<template>
    <v-dialog v-model="show" persistent max-width="400">
      <v-card>
        <v-card-title class="headline" t-data="headline">
          <slot name="header">
            <h4 :class="`${headlineColor}--text`">{{ title }} <v-icon v-if="icon" :color="headlineColor">{{ icon }}</v-icon></h4>
          </slot>
        </v-card-title>
        <v-card-text t-data="card-text">
          <div class="content-detail" v-html="text"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="alert-close" :color="buttonColor" class="button-bold" text @click="close" t-data="action-btn">{{ buttonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: "AlertError",
    props: {
      show: {type: Boolean},
      close: {type: Function},
      msg: {type: String},
      icon: {
        type: String,
        required: false,
        default: 'error'
      },
      title: {
        type: String,
        required: false,
        default: 'Error'
      },
      headlineColor: {
        type: String,
        required: false,
        default: 'red'
      },
      headlineClass: {
        type: String,
        required: false,
        default: ''
      },
      buttonText: {
        type: String,
        required: false,
        default: 'Close'
      },
      buttonColor: {
        type: String,
        required: false,
        default: 'black darken-1'
      },
    },
    methods: {
      closePopup() {
        this.close()
      }
    },
    computed:{
      text() {
        return this.msg ? this.msg : 'Incomplete information'
      }
    }
  }
</script>


<style scoped>

</style>

