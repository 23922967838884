<template>
  <v-container class="edit-container px-10" fluid>
    <!-- Breadcrumbs -->
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isDeleteUser"
      :title="'Remove Confirmation'"
      subTitle="Do you want to remove this user? This process cannot be undone."
      :close="closeDeleteUserPopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDeleteUser"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error
      :show="isError"
      :msg="msgError"
      :close="closeAlert"
    ></v-alert-error>
    <add-user-popup :show="showAddUserPopup" @cancel="showAddUserPopup = false" @confirm="showAddUserPopup = false"></add-user-popup>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} User Roles
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isEditMode && hasPermDelete()"
            class="ma-2 white-text-button button-bold"
            color="danger500Color"
            t-data="remove-btn"
            @click="showDeletePopup"
          >
            <v-icon left light>
              mdi-trash-can-outline
            </v-icon>
            Remove
          </v-btn>
          <v-btn
            class="ma-2 button-bold"
            color="whiteColor"
            t-data="cancel-btn"
            @click="showCancelPopup"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!disabledEdit"
            class="white-text-button ma-2 button-bold"
            color="#1070C4"
            @click="save"
            t-data="save-btn"
          >
            <v-icon left light>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!-- Form -->
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            User Role Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            :disabled="disabledEdit"
            color="primary"
            v-model="roleObject.is_active"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="6">
              <p
                class="body-regular warning-content mb-6"
                t-data="warning-content"
              >
                All fields are required.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Role
              </p>
              <v-text-field
                outlined
                required
                dense
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Input Role Name'"
                :rules="RoleRules"
                v-model="roleObject.codename"
                :disabled="disabledEdit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Branch
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Branch"
                v-model="roleObject.org"
                :disabled="disabledEdit"
                :menu-props="{bottom: true, offsetY: true}"
                :items="orgObject.data"
                :rules="OrgRules"
                item-text="name"
                item-value="codename"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="red--text">{{
                convertDataPermission().length === 0
                  ? this.roleFirstSelect
                    ? 'Please set permissions at least one permission.'
                    : ''
                  : ''
              }}</span>
            </v-col>
          </v-row>
          <div>
            <v-data-table
              :headers="headers"
              :items="permissions"
              class="elevation-1 custom-table"
              :disable-pagination="true"
              :disable-sort="true"
              :hide-default-footer="true"
            >
              <template v-slot:item.functionName="{item}">
                <div>
                  <span>{{ permissionName(item.functionName) }}</span>
                  <br />
                  <span>{{ description(item.functionName) }}</span>
                </div>
              </template>
              <template v-slot:item.view="{item}">
                <v-checkbox
                  color="secondary"
                  v-model="item.view"
                  :disabled="disableView(item)"
                ></v-checkbox>
              </template>
              <template v-slot:item.add="{item}">
                <v-checkbox
                  color="secondary"
                  v-model="item.add"
                  :disabled="disableAdd(item)"
                  :v-if="item.view ? (item.add = item.add) : (item.add = false)"
                  @change="roleChange()"
                ></v-checkbox>
              </template>
              <template v-slot:item.edit="{item}">
                <v-checkbox
                  color="secondary"
                  v-model="item.edit"
                  :disabled="disableEdit(item)"
                  :v-if="
                    item.view ? (item.edit = item.edit) : (item.edit = false)
                  "
                  @change="roleChange()"
                ></v-checkbox>
              </template>
              <template v-slot:item.remove="{item}">
                <v-checkbox
                  color="secondary"
                  v-model="item.remove"
                  :disabled="disableRemove(item)"
                  :v-if="
                    item.view
                      ? (item.remove = item.remove)
                      : (item.remove = false)
                  "
                  @change="roleChange()"
                ></v-checkbox>
              </template>
              <template v-slot:item.bulk="{item}">
                <v-checkbox
                  v-if="isInAllPermissions(item, 'Bulk')"
                  color="secondary"
                  v-model="item.bulk"
                  :disabled="disableBulk(item)"
                  :v-if="item.view ? (item.bulk = item.bulk) : (item.bulk = false)"
                  @change="roleChange()"
                ></v-checkbox>
              </template>
            </v-data-table>
          </div>

          <div v-show="isEditMode" t-data="user-list-container" style="margin-top:30px">
            <v-row class="align-center mr-0 mb-4" style="margin-left: 1px;">
              <h6 class="pageHeadline" t-data="page-headline">
                User in Organization
              </h6>
              <v-spacer></v-spacer>
              <v-btn
                id="add-btn"
                v-if="!disabledEdit"
                large
                color="primary600Color"
                @click="add"
                class="white-text-button button-bold"
                t-data="add-btn"
              >
                <v-icon left>mdi-plus-circle-outline</v-icon>Add User
              </v-btn>
            </v-row>
            <div>
              <v-data-table
                :headers="userHeaders"
                :items="roleObject.user_roles"
                class="elevation-1"
              >
                <template v-slot:item.email="{item}">
                  <!---- auto complete -->
                  <div v-if="!item.id">
                    <v-autocomplete
                      v-model="item.user"
                      :loading="userLoading"
                      :items="users"
                      item-text="email"
                      return-object
                      class="mx-2"
                      :search-input.sync="searchInput"
                      filled
                      dense
                      placeholder="Select Email"
                      @change="selectedUser(item)"
                      style="margin-top:3%"
                      solo
                    >
                      <template v-slot:selection="data">
                        <div>{{ data.item.email }}</div>
                      </template>
                      <template v-slot:item="data">
                        <div>{{ data.item.email }}</div>
                      </template>
                    </v-autocomplete>
                  </div>
                  <span v-else>{{ item.user.email }}</span>
                </template>
                <template v-slot:item.fullname="{item}">
                  <span
                    >{{ item.user.first_name }} {{ item.user.last_name }}</span
                  >
                </template>
                <template v-slot:item.action="{item}">
                  <v-icon
                    v-if="!disabledEdit"
                    t-data="delete-action"
                    class="px-2 action-icon"
                    @click="deleteUser(item)"
                    small
                    >mdi-trash-can-outline</v-icon
                  >
                </template>
              </v-data-table>
              <div class="table-footer-prepend" t-data="total-result-content">
                <div class="vertical-center pl-4">
                  <b>{{ roleObject.user_roles.length }}</b> Search results
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row
      class="align-center mr-0 mt-4 mb-3"
      style="margin-left: 1px; margin-right: 0;"
    >
      <v-spacer></v-spacer>
      <v-btn
        v-if="isEditMode && hasPermDelete()"
        class="ma-2 white-text-button button-bold"
        color="danger500Color"
        t-data="remove-btn"
        @click="showDeletePopup"
      >
        <v-icon left light>
          mdi-trash-can-outline
        </v-icon>
        Remove
      </v-btn>
      <v-btn
        class="ma-2 button-bold"
        color="whiteColor"
        t-data="cancel-btn"
        @click="showCancelPopup"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="!disabledEdit"
        class="white-text-button ma-2 button-bold"
        color="#1070C4"
        @click="save"
        t-data="save-btn"
      >
        <v-icon left light>
          mdi-content-save-outline
        </v-icon>
        Save
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import AlertError from '../../components/AlertError'
import PopupLoading from '../../components/PopupLoading'
import {actionEnum, mobileAppName} from '@/constants/constants'
import {disabledRoles} from '@/constants/role-permission'
import {hasPermissionsByOrgs, snackbarFailContent} from '@/helper/helper'
import AlertConfirm from '@/components/AlertConfirm'
import SnackBar from '@/components/SnackBar'
import AddUserPopup from '@/views/role-permission/AddUserPopup'

export default {
  components: {
    SnackBar,
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-popup-loading': PopupLoading,
    AddUserPopup
  },
  data() {
    return {
      pageName: 'user role',
      subTitle: 'This Role will no longer available to all users in this branch after the role removal. This process cannot be undone.<br><br>Are you sure you want to remove?',
      headers: [
        {
          text: 'Permission',
          align: 'start',
          sortable: false,
          value: 'functionName',
          width: '50%'
        },
        {text: 'View', value: 'view', width: '10%'},
        {text: 'Add', value: 'add', width: '10%'},
        {text: 'Edit', value: 'edit', width: '10%'},
        {text: 'Remove', value: 'remove', width: '10%'},
        {text: 'Bulk', value: 'bulk', width: '10%'},
      ],
      userHeaders: [
        {text: 'Full Name', value: 'fullname', sortable: false, width: '45%'},
        {text: 'Email', value: 'email', sortable: false, width: '45%'},
        {text: 'Action', value: 'action', sortable: false, width: '10%'}
      ],
      searchInput: '',
      isEditMode: false,
      role: '',
      RoleRules: [v => !!v || 'Role is required'],
      OrgRules: [v => !!v || 'Branch is required'],
      roleFirstSelect: false,
      isError: false,
      msgError: '',
      isWarning: false,
      isLeave: false,
      showDialog: false,
      showAddUserPopup: false,
      isDeleteUser: false,
      isCancel: false,
      userDelete: null,
      isCreateFail: false,
      snackTitle: '',
    }
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    if (this.isEditMode) {
      await this.setRole(this.$route.params.id)
      this.getUsers()
    }
    this.setOrgInfo()
    await this.getPermission()
  },
  beforeMount() {
    this.resetState()
  },
  methods: {
    hasPermDelete() {
      return (
        this.roleObject.org.length > 0 &&
        hasPermissionsByOrgs('CanDeleteRolePermission', [this.roleObject.org])
      )
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'role-permission-list'})
    },
    permissionName(functionName) {
      switch (functionName) {
        case 'QRCode':
          return `QR Code Management`
        case 'Symptomatic':
          return `Symptomatic Areas`
        default:
          return functionName
      }
    },
    description(functionName) {
      switch (functionName) {
        case 'News':
          return `Manage news contents for ${mobileAppName} application.`
        case 'Banner':
          return `Manage banner contents for ${mobileAppName} application.`
        case 'Package':
          return `Manage package and promotion contents for ${mobileAppName} application.`
        case 'Customer':
          return `Manage customers of ${mobileAppName} application.`
        case 'User':
          return `Manage user and account of ${mobileAppName} portal.`
        case 'RolePermission':
          return `Manage role and permission contents for ${mobileAppName} application.`
        case 'Coupon':
          return `Manage coupon contents for ${mobileAppName} application.`
        case 'QRCode':
          return `Manage QR Code Management contents for ${mobileAppName} application.`
        case 'Symptomatic':
          return `Manage Symptomatic areas contents for ${mobileAppName} application.`
        default:
          return `Manage ${functionName} contents for ${mobileAppName} application.`
      }
    },
    disableView(item) {
      if (this.disabledEdit) return true
      return disabledRoles.includes(`CanView${item.functionName}`)
    },
    disableAdd(item) {
      if (this.disabledEdit) return true
      if (disabledRoles.includes(`CanAdd${item.functionName}`)) return true
      return !item.view
    },
    disableEdit(item) {
      if (this.disabledEdit) return true
      if (disabledRoles.includes(`CanEdit${item.functionName}`)) return true
      return !item.view
    },
    disableRemove(item) {
      if (this.disabledEdit) return true
      if (disabledRoles.includes(`CanDelete${item.functionName}`)) return true
      return !item.view
    },
    disableBulk(item) {
      if (this.disabledEdit) return true
      if (disabledRoles.includes(`CanBulk${item.functionName}`)) return true
      return !item.view
    },
    isInAllPermissions(item, action) {
      const permissionNames = this.allPermissions.map(p => p.codename)
      return permissionNames.includes(`Can${action}${item.functionName}`);
    },
    selectedUser(obj) {
      obj.id = obj.user.id
      this.updateUsers(this.users)
    },
    add() {
      this.showAddUserPopup = true
    },
    async confirmDelete() {
      const isSuccess = await this.deleteRole()
      if (isSuccess) {
        this.$router.push({
          name: 'role-permission-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup() {
      this.setDeleteObj({id: this.$route.params.id})
      this.setDeletePopup(true)
    },
    confirmDeleteUser() {
      this.isDeleteUser = false
      this.setUserObj(this.userDelete)
      this.userDelete = null
    },
    closeDeleteUserPopup() {
      this.isDeleteUser = false
      this.userDelete = null
    },
    deleteUser(user) {
      if (!user.isNewUser) {
        this.isDeleteUser = true
        this.userDelete = user
        return
      }
      this.setUserObj(user)
    },
    setUserObj(user) {
      this.roleObject.user_roles = this.roleObject.user_roles.filter(function(
        obj
      ) {
        return obj.id !== user.id
      })
    },
    roleChange() {
      this.roleFirstSelect = true
    },
    convertDataPermission: function() {
      return this.permissions.reduce((acc, curr) => {
        if (curr.view) acc = [...acc, `CanView${curr.functionName}`]
        if (curr.add) acc = [...acc, `CanAdd${curr.functionName}`]
        if (curr.edit) acc = [...acc, `CanEdit${curr.functionName}`]
        if (curr.remove) acc = [...acc, `CanDelete${curr.functionName}`]
        if (curr.bulk) acc = [...acc, `CanBulk${curr.functionName}`]
        return acc
      }, [])
    },
    async save() {
      if (this.validate()) {
        this.roleObject.permissions = this.convertDataPermission()
        if (!this.isEditMode) {
          try {
            await this.create(this.roleObject)
            this.roleFirstSelect = false
            this.resetState()
            this.getPermission()
            this.$router.push({
              name: 'role-permission-list',
              query: {isCreateSuccess: true, snackTitle: 'created'}
            })
            return this.$refs.form.resetValidation()
          } catch (e) {
            this.isError = true
            this.msgError = e.message
            return
          }
        } else {
          try {
            let payload = {pk: this.$route.params.id, object: this.roleObject}
            await this.edit(payload)
            this.$router.push({
              name: 'role-permission-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } catch (e) {
            this.isError = true
            this.msgError = 'Incomplete information'
            return
          }
        }
      }
    },
    closeAlert() {
      this.isError = false
    },
    validate() {
      this.roleFirstSelect = true
      if (
        this.$refs.form.validate() &&
        this.convertDataPermission().length != 0
      ) {
        return true
      }
    },
    ...mapActions('rolePermission', [
      'setDeleteObj',
      'setDeletePopup',
      'setOrgInfo',
      'deleteRole',
      'getPermission',
      'setRole',
      'create',
      'resetState',
      'getUsers',
      'updateUsers',
      'edit'
    ])
  },
  computed: {
    textMode() {
      return this.isEditMode ? 'Edit' : 'Add'
    },
    breadcrumbs() {
      return [
        {text: 'Role & Permission', links: false},
        {text: 'User Role', links: true, to: '/role-permissions'},
        {text: `${this.textMode} User Role`, links: false}
      ]
    },
    disabledEdit() {
      if (this.roleObject.org.length > 0) {
        return (
          this.isEditMode &&
          !hasPermissionsByOrgs('CanEditRolePermission', [this.roleObject.org])
        )
      } else {
        return false
      }
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('rolePermission', [
      'isDelete',
      'isLoading',
      'roleObject',
      'orgObject',
      'permissions',
      'userLoading',
      'users',
      'allPermissions'
    ])
  }
}
</script>

<style scoped>
.v-form .col,
.container .col {
  padding-top: 0;
  padding-bottom: 0;
}
.disable-delete-icon.v-icon.v-icon.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
