<template>
    <v-dialog
            v-model="isLoading"
            width="50%"
            persistent
    >
      <v-card
              color="primary"
      >
        <v-card-text>
          <v-card-title t-data="headline">
            <p style="color: white">Loading</p>
          </v-card-title>
          <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: "PopupLoading",
    props: {
      isLoading: {type: Boolean},
    },
  }
</script>

<style scoped>

</style>