<template>
  <v-dialog v-model="show" persistent max-width="550">
    <v-card class="edit-container">
      <v-card-title class="headline" t-data="headline">
        <h4>Add User</h4>
      </v-card-title>
      <v-card-text t-data="card-text" class="card-text pb-4">
        <v-autocomplete
          id="search-email"
          v-model="user"
          :items="users"
          item-text="email"
          return-object
          background-color="grayscale"
          prepend-inner-icon="search"
          :search-input.sync="searchInput"
          append-icon=""
          filled
          placeholder="Select Email"
          @change="selectedUser"
          solo
          flat
          dense
          single-line
        >
          <template v-slot:selection="data">
            <div>{{ data.item.email }}</div>
          </template>
          <template v-slot:item="data">
            <v-list-item-content class="primaryTextColor--text"> {{ data.item.email }}</v-list-item-content>
            <v-list-item-action v-if="data.item.disabled" class="success600Color--text">
              Added
            </v-list-item-action>
          </template>
        </v-autocomplete>
        <v-layout fluid v-resize="onResize">
          <v-data-table
            :headers="headers"
            :items="usersInRole"
            :sort-desc="true"
            :must-sort="false"
            class="custom-table"
            fixed-header
            style="width: 100%"
            :height="
              windowSize.y - heightOptions.header - heightOptions.tableFooter
            "
            :hide-default-footer="true"
          >
            <template v-slot:item.email="{item}">
              <span>{{ item.user.email }}</span>
            </template>
            <template v-slot:item.fullname="{item}">
              <span>{{ item.user.first_name }} {{ item.user.last_name }}</span>
            </template>
            <template v-slot:item.action="{item}">
              <v-icon
                t-data="delete-action"
                class="px-2"
                color="secondaryText"
                @click="deleteUser(item)"
                small
                >mdi-trash-can-outline</v-icon
              >
            </template>
          </v-data-table>
        </v-layout>
      </v-card-text>
      <v-card-actions t-data="card-action">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-button button-bold"
          t-data="cancel-btn"
          color="info600Color"
          text
          @click="cancelPopup"
        >
          Cancel</v-btn
        >
        <v-btn
          class="confirm-button button-bold"
          t-data="confirm-btn"
          color="info600Color"
          text
          @click="confirmPopup"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  props: {
    show: {type: Boolean},
    close: {type: Function},
    confirm: {type: Function}
  },
  data: () => ({
    user: [],
    heightOptions: {
      header: 400,
      tableFooter: 20
    },
    windowSize: {
      x: 0,
      y: 0
    },
    headers: [
      {text: 'Email', value: 'email', sortable: false, width: '45%'},
      {text: 'Full Name', value: 'fullname', sortable: false, width: '45%'},
      {text: 'Action', value: 'action', sortable: false, width: '10%'}
    ],
    searchInput: '',
    usersInRole: [],
  }),
  computed: {
    ...mapState('rolePermission', ['roleObject', 'users'])
  },
  watch: {
    'roleObject.user_roles'() {
      this.resetUsersInRole()
    },
  },
  mounted() {
    this.resetUsersInRole()
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    selectedUser(obj) {
      this.usersInRole = [{id: obj.id, user: obj, isNewUser: true}].concat(
        this.usersInRole
      )
      this.updateUsers({users: this.users, usersInRole: this.usersInRole})

      // clear data in input
      this.$nextTick(() => {
        this.searchInput = ''
        this.user = null
      })
    },
    deleteUser(user) {
      this.setUserObj(user)
      this.updateUsers({users: this.users, usersInRole: this.usersInRole})
    },
    setUserObj(user) {
      this.usersInRole = this.usersInRole.filter(
        obj => obj.id !== user.id
      )
    },
    cancelPopup() {
      this.resetUsersInRole()
      this.$emit('cancel')
    },
    confirmPopup() {
      this.roleObject.user_roles = [...this.usersInRole]
      this.$emit('confirm')
    },
    resetUsersInRole() {
      this.usersInRole = [...this.roleObject.user_roles]
      this.updateUsers({users: this.users, usersInRole: this.usersInRole})
    },
    ...mapActions('rolePermission', ['updateUsers'])
  }
}
</script>

<style lang="scss" scoped>
.card-text {
  border-bottom: var(--divider-color) 1px solid;
}
</style>
